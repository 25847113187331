import './plugins/jquery.magnific-popup';
import './plugins/jquery.slides';
import '../../../node_modules/colcade/colcade';
import '../../../node_modules/imagesloaded/imagesloaded';

$(document).ready(function() {
	// Initialization for the lightbox.
	var lightboxInitialize = function() {
		$('a.colorbox').magnificPopup({
			type: 'image',

			gallery: {
				enabled: true
			},

			closeMarkup: '<button title="%title%" class="mfp-close fa fa-times-circle-o"></button>'
		});
	};

	// Initialize slideshow.
	if ($('main > div.slideshow > div.slide').length > 1) {
		$('main > div.slideshow').slidesjs({
			width: 1000,
			height: 350,

			pagination: {
				active: false
			},

			navigation: {
				active: false
			},

			play: {
				active: true,
				interval: 3000,
				auto: true,
				pauseOnHover: true,
				restartDelay: 1500
			}
		});
	}

	// Initialize gallery.
	$('main > div.gallery').imagesLoaded(function () {
		$('main > div.gallery').attr('data-masonry-initialized', true);
		$('main > div.gallery').colcade({
			columns: '.grid-col',
			items: '.item'
		});
	});

	// Initialize lightbox if any links are found.
	if ($('a.colorbox').length > 0) {
		lightboxInitialize();
	}
});
